import { datadogRum } from "@datadog/browser-rum";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// eslint-disable-next-line import/no-unresolved
import "virtual:svg-icons-register";
import Vue3TouchEvents from "vue3-touch-events";
import { Tabs, Tab } from "vue3-tabs-component";
// import Hotjar from "@hotjar/browser";
import { VueMasonryPlugin } from "vue-masonry";
import FancyImage from "./components/FancyImage/FancyImage.vue";
import router from "./router";
import App from "./App.vue";

import "./assets/styles/base.scss";

// hotfix for link that were gererated with a double slash after the origin
if (window.location.pathname.startsWith("//get-started/checkout/")) {
  window.location.pathname = window.location.pathname.substring(1);
}

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

if (
  import.meta.env.VITE_APP_ENV === "stg" ||
  import.meta.env.VITE_APP_ENV === "prod"
) {
  datadogRum.init({
    applicationId: "7420b042-274f-4b74-a73f-8feadadf242c",
    clientToken: "pub04e5084f2fbf3eeff63ab4ec49d6ff54",
    site: "datadoghq.com",
    service: "ways2well-website",
    env: import.meta.env.VITE_APP_ENV,
    allowedTracingUrls: ["amazonaws.com"],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: "allow",
  });
}

// const siteId = import.meta.env.VITE_APP_HOTJAR_SITE_ID;
// const hotjarVersion = 6;
// Hotjar.init(siteId, hotjarVersion);

app.use(router);
app.use(Vue3TouchEvents);
app.use(pinia);

app.component("Imgix", FancyImage);

// app.use(VueImgix, {
//   domain: "w2w-web-media.imgix.net",
//   defaultIxParams: {
//     // This enables the auto format imgix parameter by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
//     auto: "format",
//   },
// });

app.component("VueTabs", Tabs);
app.component("VueTab", Tab);

app.mount("#app");

app.use(VueMasonryPlugin);
